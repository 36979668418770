@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;1,200&display=swap");


:root {
    --primary-color: #031a0f;
    --secondary-color: #0f722d;
    --color-white: #f5f5b8;
    --secondary-font:  Arial, Helvetica, sans-serif;
    --primary-font: 'poppins', sans-serif;
}

html{
    scroll-behavior: smooth;
}
body{
    font-family: var(--secondary-font) ;
    /* background-image: url("../images/dark-green-back.jpg"); */
    background-color: var(--primary-color);
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
}

ul li {
    margin: 1em ;
    color: rgb(0, 0, 0);
    font-family: var(--primary-font);
}



.nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background: var(--primary-color);
    width: 60%;
    z-index: 999;
    transform: translateX(100%);
    text-transform: uppercase;
    transition: transform .5s ease-in-out;

}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #031a0f79;
    top: 0;
    transition: transform .5s ease-in-out;
}

nav ul {
    margin-top: 6em;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;  
}

a {
    color: var(--color-white);
    padding: .75em ;
    display: block;
    width: 100%;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    /* background-color: var(--secondary-color); */
    color: var(--color-white);
}

.menu {
    color: var(--color-white);
    width: 4em;
    height: 4em;
    z-index: 70;
}


.close-nav {
    height: 2em;
    width: 2em;
    color: var(--color-white);
    float: right;
    margin-top: 2em;
    margin-right: 2em;
}
.dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-icon {
      display: flex ;
  }
  
  .dropdown-icon i {
      display: block;
      padding: unset;
      padding-left: 0.1em;
      padding-top: 0.9em;
      width: 3em;
      color: var(--color-white);
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--primary-color);
    width: 7em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

  }
  
  .dropdown-content a {
    color: burlywood;
    padding: 1em 0 1em 0 ;
    margin: 0;
    text-decoration: none;
    display: block;
    text-align: center  ;
  }
  
  .dropdown-content a:hover {
      background-color: var(--primary-color)
    }
  
  .dropdown:hover .dropdown-content {
      display: block;
    }


.open-nav {
    transform: translateX(0%) !important;
}


.hero-div {
    margin-top: 1em;
    width: 90%;
    height: auto;
    display: flex;
    margin-inline: auto;
    justify-content: space-between;

    /* background: url(/images/dark-green-back.jpg); */
}

.hero-img{
    width: 9em;
}

.gov-logo {
    height: 4em;
    margin-left: -1em;
}

.home {
    margin-bottom: 3em;
}

.descriptions{
    width: 100vw;
    margin-inline: auto;
}

.ogp-section {
    width: 90vw;
    color: var(--color-white);
    text-align: center;
}


.item-1, .item-2 {
    padding: 1em;
    margin-top: 2em;
    font-size: 0.9em;
    margin-inline: auto;
    border-radius: 5px;
    border: 1px solid rgb(2, 44, 5);
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.ogp-section-title{
    padding-top: 1em;
    font-size: 1.4em;
}

.rider {
    font-size: small;
    margin-top: 3em;
    color: var(--color-white);
    text-align: center;
}

hr {
    background-color: var(--color-white);
    width: 80%;
}

.contact-head{
    margin-top: 2em;
    text-align: center;
    color: var(--color-white);
}
.contact-us{
    font-size: 0.7em;
    color: var(--color-white);
    text-align: center;
    
}

.contact-us a{
    color: burlywood;
}

.contact-2 {
    margin-top: 4em;
}

/* contact form...................*/
.contact-form-container {
    margin-top: 3em;
    width: 70vw;
    margin-inline: auto;
}


.contact-form {
    width: inherit;
    color: var(--color-white);
    margin-inline: auto;
}

.contact-form input[type=text], .contact-form input[type=email] {
    border-radius: 5px;
    margin-bottom: 1.5em;
    width: inherit;
    height: 3em;
    padding-left: 1em;
    background-color: var(--color-white);
}


.contact-form select {
    border-radius: 5px;
    width: inherit;
    height: 3em;
    background-color: var(--color-white);
    margin-bottom: 1.5em;
}

.contact-form textarea{
    border-radius: 5px;
    background-color: var(--color-white);
    width: inherit;
    height: 10em;
}

input[type=submit] {
    width: inherit;
    border-radius: 5px;
    height: 4em;
    margin-top: 1em;
    margin-inline: auto;
    color: var(--color-white);
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}

input[type=submit]:hover{
    background-color: var(--primary-color);

}

/* current-tab ....................... */

.current-tab{
    color: green !important;
        padding-bottom: 1em;
        /* border: unset !important; */
}

/* thematic-areas-mobile............................. */

.thematic-area-title{
    text-align: center;
    color: var(--color-white);
}

.ta-picture{
    height: 100%;
    display: none;
}
.ta-main-area {
    margin-bottom: 3em;
    margin-top: 4em;
    display: flex ;
    margin-left: auto;
    margin-right: auto;
    
}

.thematic-area-nav{
    height: auto;
    background: var(--primary-color);
    border-right: 2px var(--color-white) solid;
    width: min-content;
    font-size: 0.9em;
    text-transform: uppercase;
}


.thematic-area-nav ul {
    display: block ;
    justify-content: space-between;
    list-style-type: none;
    padding: 1em;

}

.thematic-area-nav ul li {
    color: var(--color-white);
    margin: 0;
    margin-top: 2em;
    font: var(--primary-font);
}

.thematic-area-nav ul li:hover {
    color: var(--secondary-color);
}


.ta-display-area{
    width: auto;
    margin-left: 2vw;
    background-color: #031a0f;
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.ta-item {
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.ta-picture {
    width: auto;
    height: 22em;
}

.ta-text {
    height: 46vh;
    width: 13em;
    color: var(--color-white);
    font-weight: bold;
    padding: 20px;
}

.ta-text.fiscal-transparency {
    background: url('../images/thematic_areas/nigerian-currencyblur.jpg');
    background-size: cover;
    
}

.ta-text.citizens-engagement {
    background: url('../images/thematic_areas/citizensEngagementblur.jpg');
    background-size: cover;
}

.ta-text.gender {
    background: url('../images/thematic_areas/genderequalityblur.png');
    background-size: cover;
}

.ta-text.extractive-transparency {
    background: url('../images/thematic_areas/extractivetransparencyblur.jpeg');
    background-size: cover;
}

.ta-text.peace-and-security {
    background: url('../images/thematic_areas/peaceblur.png');
    background-size: cover;
}

.ta-text h2{
    text-decoration: underline  ;
}

/* events........................................ */
.year {
    color: var(--color-white);
    text-align: center;
    margin-top: 2em;
}
.events-page {
    margin-bottom: 10em;
}

.sidenav {
    border-radius: 5px;
   
    width: auto;
  position: fixed;
  z-index: 1;
  top: 40vh;
  left: -2em;
  background: #eee;
  /* overflow-x: hidden; */
  padding: 1px;
  background-color: #031a0fce;
  border: 1px solid var(--secondary-color);
  border-left: none;
}

.sidenav ul {
    display: block ;
    justify-content: space-between;
    list-style-type: none;


}

.sidenav ul li {
    cursor: pointer;
    display: block;
    color: var(--color-white);
    margin-bottom: 2em;
   font-size: large;
}

.sidenav ul li:hover{
    color: #0f722d;
}

.events {
    display: flex;
    column-gap: 2em;
    justify-content: center;
    flex-wrap: wrap;
    margin-inline: auto;
    grid-column: 2;
    margin-bottom: 5em;
    width: fit-content;
    color: var(--color-white)
}

.event_item {
   margin-top: 3em;
    width: auto;
    max-width: fit-content;
    height: auto;
    border: 1px solid rgb(2, 44, 5);
    color: var(--color-white);
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.event_pic {
    width: 100vw;
    max-width: 30em;
    /* width: 30em; */
    height: 20em;
    margin-inline: auto;
}

.event_item p {
    max-width: 30em;
    margin-inline: auto;
    text-align: center;
}
.merged-event{
    width: auto;
}

.merged-event-p{
    color: var(--color-white);
    text-align: center;
    width: 50vw;
    margin-inline: auto;
}

  /* milestones.................................... */
  .milestones-body {
       
    height: auto;
    width: 90vw;
    margin-bottom: 3em;
    padding-bottom: 2em;
}

.commitment-heading{
    margin-left: 5vw;
   
    margin-top: 3em;
    color: #FFFFF0;
}
.table {
    margin-left: 3vw;
    border-collapse: collapse;
    margin-bottom: 2em;
    color: var(--color-white);
    width: 94vw;
    
}

.table tr{   
   height: 1em;
   border-bottom: 1px solid white;
   padding: 9em;
  
}

.table tr td {
    padding: 1em;
}

.progress-keys {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    margin-left: 5vw;
}

.completed-tag {
    background-color: rgb(8, 214, 8);
    padding: 1em;
}
.ongoing-tag {
    background-color: orange;
    padding: 1em;
}

.not-started-tag {
    background-color: red;
    padding: 1em;
}

.completed-item{
    background-color: rgb(8, 214, 8);
    width: 1em;

}

.ongoing-item {
    background-color: orange;

}
.not-started-item {
    background-color: red;           

}
.progress-col {
    width: fit-content;
}

.fill {
    width: 50vw;
}

.pagination {
    margin-left: 30vw;
    display: block;
    margin-top: -4.8em;
   
  }
  
  .pagination a {
    width: unset;
    margin: unset;
    color: var(--color-white);
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    margin-bottom: 5em;
    
  }
  
  .pagination a.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .pagination a:hover:not(.active) {
      background-color: #ddd;
    }


/* Partners....................................... */

.tearfund-logo img{
   width: 50vw;
}

/* .crudan-logo{
    
} */

.to-flex {
    display: flex;
    margin-left: 3vw;
}
.crudan-logo img{
    height: 7em;

}

.giz-logo{
    max-width: 34vw;
    display: flex;
    margin-left: 3vw;
}
.giz-logo img { 
    height: 5em;
   
}
.giz2 {
    max-width: 30vw;
    max-height: 5em;
}

.budgit-logo img {
    max-height: 6em;

}

.dev-gateway-logo img {
    max-height: 6em;
    margin-left: 6vw;

}

.center-lsd-logo img {
    max-height: 6em;
    margin-left: 6vw;

}
.partners a{
    padding: 0;
}
/* .dataphyte-logo{
    margin-left: 40vw;
} */

.acc-lab-logo img{
   max-height: 3em;
   ;
}

.ppdc-logo img{
    max-width: 90vw;
    margin-left: 4vw;
}

.other-partners{
    text-align: center;
    font-size: 2em;
    color: var(--color-white);
}

.carousel {
    width: 60vw !important;
    margin-bottom: 4em;
    margin-inline: auto;
}
.rec-dot {
    background-color: var(--color-white) !important;
}
.rec-dot_active{
    box-shadow:0 0 1px 3px rgb(24 183 29) !important;;
}

.item {
    text-align: center;
    color: var(--color-white);
    height: auto
}

.item div {
    margin-bottom: 1em;
}

/* steering committee ................................... */

.sc-member-row{
    width: fit-content;
    margin-inline: auto;
}

.sc-member {
    
    margin-top: 2em;
    margin-bottom: 6em;
    padding-bottom: 1em;
    width: 80vw;
    height: fit-content;
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);

}

.sc-member-position {
    color: #4CAF50 !important;
}

.sc-member-img {
    width: 63vw;
    height: 20em;
    margin-inline: auto;

}

.sc-member-img img {
    
    width: inherit;
    height: inherit;
}


.sc-member p{
    color: var(--color-white);
    text-align: center;
}

.steering-committee-table {
    border-collapse: collapse;
    margin-inline: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    color: var(--color-white);
    width: 90vw;
    
}

.steering-committee-table tr{   
   height: 1em;
   border-bottom: 1px solid white;
   padding: 9em;
  
}

.steering-committee-table tr td {
    padding: 0.5em;
}


/* join us................................. */
.join-pre {
    color: white;
    font-size: 0.8em;
    line-height: 2em;
    text-align: justify;

}

.join_us {
    margin: 1em;
    padding: 2em;
    background-color: #031a0f;
    border: 1px solid var(--secondary-color);
    width:fit-content;
    margin-inline: auto;
}

.join-area {
    margin-top: 3em;
    margin-bottom: 3em;
    width: 90vw;
    margin-inline: auto;
    text-align: center;
    align-content: center
}

.join-button-div {
    width: fit-content;
    margin: 1em auto;
}

/* tablet view........................................................................ */
@media only screen and (max-width: 780px) {
    .menu  .close {
        display: block;
    }


    .gov-item, .ogp-item{
        max-width: 26em;
        margin-inline: auto;
    }

}

/* desktop view......................................................................... */
@media only screen and (min-width: 780px) {
    
    .nav {
        transform: translateX(0);
        position: unset;
        display: block;
        width: 80vw;
        height: auto;
        background:  none;
        border-left: 0;
        margin-top: 4em;
        margin-inline: auto;
    }

    .nav-container {
        width: 100%;
       
    }

    .menu {
        display: none;
    }

    .nav .close {
        display: none;
    }

    ul {
        width: auto;
        margin-inline: auto;
        display: flex; 
        margin-top: 0em !important;
        padding: 0em;
       
    }

    ul li {
        margin: unset;
        color: var(--color-white);
        margin-left: 1vw;
        /* border: 1px solid green; */
    }


    a {
        color: var(--color-white);
        font-size: 0.9em;
        white-space: nowrap;
        padding: 0;
        color: burlywood;
    }

    a:hover {
        color: green !important;
    }
     .current-tab {
        
        border-bottom: 1px solid green;
    }

    .hero-img {
        width: 26em;
    }
    .gov-logo{
        width: 10em;
        height: 10em;
        margin-top: 1.5em;
        
    }

      .dropdown {
        position: relative;
        display: inline-block;
      }
      .dropdown-icon {
          display: flex ;
      }
      
      .dropdown-icon i {
          display: unset;
          padding: unset;
          padding-left: 0.8em;
          /* padding-bottom: 1em; */
          width: 3em;
          color: burlywood;
      }
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: var(--primary-color);
        width: 7em;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;

      }
      
      .dropdown-content a {
        color: burlywood;
        padding: 1em 0 1em 0 ;
        margin: 0;
        text-decoration: none;
        display: block;
        text-align: center  ;
      }
      
      .dropdown-content a:hover {
          background-color: var(--primary-color)
        }
      
      .dropdown:hover .dropdown-content {
          display: block;
        }
      

    header {
        height: 2em;
    }

    .hero-div{
        margin-inline: auto;
        width: 75%;
        justify-content: space-between;
    }

    .hero-img{
        height: 14em;

    }
    

    
    .ogp-section {
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0);
        margin-inline: auto;
    }
    
    .descriptions {
        margin-inline: auto;
        width: 80vw;
        margin-top: 4em;
        display: flex;
        justify-content: space-between;
    }

    .item-1, .item-2 {
        font-size: unset;
        border-radius: 5px;
        width: 35vw;
        border: 1px solid rgb(2, 44, 5);
        color: var(--color-white);
        box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
        
    }

    .ogp-section-title {
        margin: 0;
        text-align: center;
        color: var(--color-white);
    }
    .ogp-aim{
        
        margin-left: 20em;
        padding: 0 2em 2em 2em;
    }

    .rider {
        margin-top: 4em;
    
        color: var(--color-white);
        text-align: center;
        }

        
        /* contact area */
    .divider {
        width: 60vw;
        margin-inline: auto;
    }

    .contact-head{
        text-align: center;
        color: var(--color-white);
    }
    .contact-us {
        font-size: unset;
        margin-inline: auto;
        color: var(--color-white);
        display: flex;
        width: 50vw;
        justify-content: space-between;
        margin-bottom: 4em;

    }
    
    .contact {
        min-width: fit-content;
    }
    .contact-2{
        margin: 0;
        width: fit-content;

       
    }
    .contact-2 h3 {
        flex-wrap: nowrap;
    }
    
    .contact-phone, .contact-mail {
       margin: unset;
       padding: 0;
    }

    /* contact form .................. */
    .contact-form-container {
        margin-top: 3em;
        width: 40vw;
        margin-inline: auto;
    }
    .contact-form select option {
        font-size: 1.5em;
    }


    /* thematic areas */

    .ta-main-area {
        margin-bottom: 9em;
        margin-top: 4em;
        display: flex ;
        
    }

    .thematic-area-nav{
       
        margin-left: 12vw;
        height: auto;
        background: var(--primary-color);
        border-right: 2px var(--color-white) solid;
        width: fit-content;
        text-transform: uppercase;
    }
    
    .thematic-area-nav ul {
        display: block ;
        justify-content: space-between;
        list-style-type: none;
        padding: 1em;

    }

    .thematic-area-nav ul li {
        cursor: pointer;
        margin: 0;
        margin-top: 2em;
        font: var(--primary-font);
    }

    .thematic-area-nav ul li:hover {
        color: var(--secondary-color);
    }

    .ta-display-area{
        width: 56vw;
        margin-left: 2vw;
        background-color: #031a0f;
        box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);

    }

    .ta-item {
        display: flex;
    }

   
    .ta-picture {
        display: block;
        width: auto;
        max-height: 22em;
        filter: blur(0px);
        -webkit-filter: blur(0px);
    }


    .ta-text {
        width: unset;
        color: var(--color-white);
        font-weight: bold;
        position: static;
        transform: translate(0,0);

        padding: 20px;
    }

    .ta-text h2{
        text-decoration: underline  ;
    }

    .thematic-area{
        
        height: auto;
        margin-left: 5vw

    }
    
    .thematic-area-title {
     text-align: center;
      margin-top: 3em;
      color:var(--color-white);
      font-size: 1.6em;
    }
    .para ,.para2{
        font-family: var(--primary-font);
        font-weight: bold;
        text-align: justify;
        
    }

    .ogp-item {
        padding: 1em;
    }

    .gov-item {
        padding: 1em;
    }
    
    .thematic-area-container {
        display: flex;
        width: 90vw;
        justify-content: space-between;
    }


    .thematic-area-item {
        width: 29vw;
        background-color: #031a0f83;
        max-width: 29vw;
        min-height: 15em;
        border: 1px solid rgb(2, 44, 5);
        color: var(--color-white);
        box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
        
    }

    .thematic-area-item b {
        color: burlywood;
    }
    
     
    .ta-text.citizens-engagement,
    .ta-text.fiscal-transparency ,
    .ta-text.gender,
    .ta-text.extractive-transparency,
    .ta-text.peace-and-security {
        background: none;
    }
    
    .ta-text h2{
        text-decoration: underline  ;
    }
    
    .fiscal {
      
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .fiscal-item {
        margin-top: 0;
        padding-top: 0;
    }
    .thematic-area-item h4, .thematic-area-item p{
        text-align: center;
        padding: 0.5em
    }

    .second-line{
        width: 60vw;
        margin-left: 15vw;
        margin-top: 2em;
        margin-bottom: 4em;
    }
    
   

    /* milestones */
    .milestones-body {
       
        height: auto;
        width: 90vw;
        margin-left: 3em;
        margin-bottom: 3em;
        padding-bottom: 2em;
    }

    .commitment-heading{
        margin-left: 10em;
        margin-top: 3em;
        color: #FFFFF0;
    }
    .table {
        border-collapse: collapse;
        margin-left: 14em;
        margin-bottom: 2em;
        color: var(--color-white);
        width: 60vw;
        padding: 3em;
        
    }

   .table tr{   
       height: 1em;
       border-bottom: 1px solid white;
       padding: 9em;
      
    }

    .table tr td {
        padding: 1em;
    }

    .progress-keys {
        display: flex;
        justify-content: space-between;
        width: 40vw;
        margin-left: 22em;
    }

    

    .completed-tag {
        background-color: rgb(8, 214, 8);
        padding: 1em;
    }
    .ongoing-tag {
        background-color: orange;
        padding: 1em;
    }

    .not-started-tag {
        background-color: red;
        padding: 1em;
    }

    .completed-item{
        background-color: rgb(8, 214, 8);
        width: 1em;

    }

    .ongoing-item {
        background-color: orange;

    }
    .not-started-item {
        background-color: red;           

    }
    .progress-col {
        width: fit-content;
    }

    .fill {
        width: 50vw;
    }

    /* steering committee */
    .steering-committee-page{
        margin-bottom: 2em;
    }

    .sc-member-row{
        display: flex;
        width: 70vw;
        margin-inline: auto;
        justify-content: space-between;
    }
    
    .sc-member {
        
        margin-top: 2em;
        margin-bottom: 2em;
        padding-bottom: 1em;
        width: 30vw;
        height: fit-content;
        box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
    
    }
    
    .sc-member-img {
        width: 23vw;
        height: 23em;
        margin-inline: auto;
    
    }
    
    .sc-member-img img {
        
        width: inherit;
        height: inherit;
    }
    
    
    .sc-member p{
        color: var(--color-white);
        text-align: center;
    }

    .smaller {
        font-size: 0.8em;
    }

    .steering-committee-table {
        border-collapse: collapse;
        margin-left: 16em;
        margin-top: 3em;
        margin-bottom: 3em;
        color: var(--color-white);
        width: 60vw;
        padding: 3em;
        
    }

   .steering-committee-table tr{   
       height: 1em;
       border-bottom: 1px solid white;
       padding: 9em;
      
    }

    .steering-committee-table tr td {
        padding: 1em;
    }

    /* partners */
    .partners {
        height: auto;   
        margin-bottom: 10em;
    
    }

    .tearfund-logo {
        margin-left: 10vw;
    }

    .tearfund-logo img{
      width: 30vw;

    }


    .crudan-logo img{
        height: 13em;
    margin-left: 20vw;
    
    }
    .giz-logo{
        width: 50vw;
        margin-top: 4em;
        margin-left: 30vw;
        justify-content: space-between;
    }
    .giz-logo img {
        height: 9em;
       
    }
    /* .giz-logo2 {
    } */

    .budgit-logo img {
        max-height: 8em;
       
        margin-left: 53vw;
    

    }
    
    .dev-gateway-logo img {
        max-height: 23em;
        margin-left: 8vw;

    }

    .center-lsd-logo img {
        margin-top: 2em;
        max-height: 10em;
        margin-left: vw;
    

    }

    .dataphyte-logo{
        margin-left: 60vw;
    }

    .acc-lab-logo {
        margin-top: -4em;
        margin-left: 30vw;
    }

    .ppdc-logo {
        margin-top: 4em;
        margin-left: 30vw;
    }

    .other-partners{
        margin-top: 3em;
        color: var(--color-white);
        text-align: center;
    }
    
    /* .qoLPS{
        width: 50vw !important;
        margin-inline: auto;
    } */

        /* milestones pagination */
    .pagination {
        display: inline-block;
        margin-left: 45vw;
        margin-top: -4.8em;
       
      }
      
      .pagination a {
        display: unset;
        width: unset;
        margin: unset;
        color: unset;
        color: var(--color-white);
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        margin-bottom: 5em;
        
      }
      
      .pagination a.active {
        background-color: #4CAF50;
        color: white;
      }
      
      .pagination a:hover:not(.active) {
          background-color: #ddd;
        }

    /* join us */
    .join-us-form {
        margin-left: 30vw;
        color: var(--color-white);
       width: 50vw;
    }

    .join-us-form label{
        color: var(--color-white);
    }
    .input {
        width: 30vw;
        height: 2.5em;
        margin-bottom: 1em;
        background-color: #FFFFF0;
        padding-left: 1em;
        border: none;
    }

    /* events */
    .event-page {
    margin-bottom: 5em;
    }
    .sidenav {
        left: 0;
        width: auto;
      /* position: fixed; */
      z-index: 1;
      /* top: 40vh; */
      /* left: 1em; */
      background: #eee;
      /* overflow-x: hidden; */
      padding: 8px;
      background-color: #031a0f;
      border: 1px solid var(--secondary-color);
      border-left: none;
    }
    
    .year {
        color: var(--color-white);
        text-align: center;
        margin-top: 2em;
    }
    .events {
        margin-bottom: 3em;
        /* width: 100vw; */
        color: var(--color-white)
    }
    .event{
        margin-left: 15vw;
        margin-right: 8vw;
        display: flex;
        width: 70vw;
        margin-top: 3em;
        justify-content: space-between;
    }
    .event_item {
        width: auto;
        max-width: fit-content;
        height: auto;
        border: 1px solid rgb(2, 44, 5);
        color: var(--color-white);
        box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .event_pic {
        width: 30em;
        height: 20em;
    }

    .event_item p {
        /* max-width: 33vw;
        margin-inline: auto; */
        text-align: center;
    }
    .awards {
        margin-left: 20vw;
    }
    .award1 {
        width: 50vw;
        height: 60vh;
    }
    /* .award2 {
        width: 39vw;
        height: 50;
    } */




    /* Join us */
    .join-pre {
        color: white;
        font-size: 1em;
        width: 57vw;
        margin-inline: auto;
    }

    .join_us {
        padding: 2em;
        background-color: #031a0f;
        border: 1px solid var(--secondary-color);
        width:fit-content;
       
    }
    
    .join-area {
        margin-top: 3em;
        margin-bottom: 3em;
        align-content: center
    }

    .join-button-div {
        display: flex;
        width: 60%;
        margin: 0 auto;
    }
    
}   